import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const Seo = ({ title, description, author }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultAuthor: author
            defaultImage: image
          }
        }
      }
    `
  )
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultAuthor,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${defaultImage}`,
    url: `${siteUrl}${pathname}`,
    author: author || defaultAuthor,
  }
  const lang = `en`

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    inLanguage: `en`,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      "@type": "Person",
      name: defaultAuthor,
    },
    copyrightHolder: {
      "@type": "Person",
      name: defaultAuthor,
    },
    copyrightYear: "2020",
    creator: {
      "@type": "Person",
      name: defaultAuthor,
    },
    publisher: {
      "@type": "Person",
      name: defaultAuthor,
    },
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={`${titleTemplate} - %s`}
    >
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && (
        <meta property="og:title" content={`Aishwarya Soni - ${seo.title}`} />
      )}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}{" "}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && (
        <meta name="twitter:title" content={`Aishwarya Soni - ${seo.title}`} />
      )}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      <meta name="image" content={seo.image} />
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default Seo
