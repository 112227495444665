import React from "react"
import { FiInstagram } from "react-icons/fi"
import { GrMail } from "react-icons/gr"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__social">
        <div className="footer__social--links">
          <div className="footer__social--link">
            <h3>Follow me</h3>
            <a
              href="https://www.instagram.com/theaishwaryasoni/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FiInstagram />
              Instagram
            </a>
          </div>
          <div className="footer__social--link">
            <h3>Shoot me an email</h3>
            <a href="mailto:soni.aishwarya06@gmail.com">
              <GrMail />
              soni.aishwarya06@gmail.com
            </a>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <p>Aishwarya Soni &copy; 2020 | All Rights Reserved</p>
        <p>
          Website by{" "}
          <a
            href="https://sakshamkapoor.me"
            target="_blank"
            rel="noreferrer noopener"
          >
            Saksham Kapoor
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
