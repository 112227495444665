import React, { useEffect, useRef, useContext } from "react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"
import { CgMenuRight, CgClose } from "react-icons/cg"
import { NavContext } from "../contexts/NavContext"
import scrollTo from "gatsby-plugin-smoothscroll"
import { navigate } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

const NavControls = () => {
  const { navOpen, navToggle } = useContext(NavContext)
  const isFirstRun = useRef(true)

  const navigateTo = target => {
    if (navOpen) {
      navToggle()
    }

    setTimeout(() => {
      navigate("/")
      setTimeout(() => {
        scrollTo(target)
      }, 750)
    }, 500)
  }

  // nav open/close animations
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    } else {
      let navTimeline = gsap.timeline({
        onStart: () => {
          document.querySelector(".navControls__open").style.visibility =
            "hidden"
        },
        onComplete: () => {
          document.querySelector(".navControls__open").style.visibility =
            "visible"
        },
      })

      if (navOpen) {
        disableBodyScroll(document.querySelector("nav"), {
          reserveScrollBarGap: true,
        })

        document
          .querySelector(".navControls")
          .classList.add("navControls--scrolled--open")

        navTimeline
          .to("nav", {
            opacity: 1,
            x: "-100vw",
            duration: 0.5,
          })
          .from("#hover-effect", {
            x: 100,
            opacity: 0,
            duration: 0.2,
            stagger: 0.125,
          })
      } else {
        enableBodyScroll(document.querySelector("nav"))

        document
          .querySelector(".navControls")
          .classList.remove("navControls--scrolled--open")

        navTimeline.to("nav", {
          opacity: 0,
          x: "0",
          duration: 0.4,
        })
      }
    }
  }, [navOpen])

  // nav scroll effect
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      gsap.core.globals("ScrollTrigger", ScrollTrigger)
    }
    const navScroll = ScrollTrigger.create({
      start: "top -50",
      end: 99999,
      toggleClass: {
        className: "navControls--scrolled",
        targets: ".navControls",
      },
    })
    return () => {
      navScroll.kill()
    }
  }, [])

  return (
    <div className="navControls">
      <div
        className="navControls__logo"
        onClick={() => navigateTo("body")}
        aria-label="Home"
      >
        <span>A</span>ishwarya <span>S</span>oni
      </div>

      <div
        className="navControls__open noTap"
        role="button"
        tabIndex="0"
        onClick={navToggle}
        aria-label="toggle navigation menu"
      >
        {navOpen && <CgClose strokeWidth="0.1" />}
        {!navOpen && <CgMenuRight strokeWidth="0.15" />}
      </div>
    </div>
  )
}

export default NavControls
