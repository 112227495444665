import React, { useContext } from "react"
import { Link } from "gatsby"
import { NavContext } from "../contexts/NavContext"
import scrollTo from "gatsby-plugin-smoothscroll"
import { navigate } from "gatsby"

const Navigation = () => {
  const { navToggle } = useContext(NavContext)
  const navigateTo = target => {
    navToggle()

    setTimeout(() => {
      navigate("/")
      setTimeout(() => {
        scrollTo(target)
      }, 750)
    }, 500)
  }

  return (
    <nav className="nav">
      <div className="nav__link-container">
        <div
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
          onClick={() => navigateTo("body")}
        >
          <span>Home</span>
        </div>
        <div
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
          onClick={() => navigateTo("#section-about-me")}
        >
          <span>About Me</span>
        </div>
        <Link
          to="/portfolio/tiktok"
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
        >
          <span>My Work</span>
        </Link>
        <Link
          to="/marketing-campaigns"
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
        >
          <span>Campaigns</span>
        </Link>
        <div
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
          onClick={() => navigateTo("#section-my-journey")}
        >
          <span>My Journey</span>
        </div>
        <div
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
          onClick={() => navigateTo("#section-testimonials")}
        >
          <span>Testimonials</span>
        </div>
        <div
          id="hover-effect"
          className="noTap"
          role="button"
          tabIndex="0"
          onClick={() => navigateTo("#section-blog")}
        >
          <span>Blog</span>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
